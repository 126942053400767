import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../apiConfig";
import classes from "./EditProfile.module.css";
import Sidebar from "../components/Sidebar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import axios from "axios";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditProfile() {
  const [categoryGroup, setCategoryGroup] = useState([]);
  const [categoriesSelected, setCategorySelected] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [saveSubcats, setSaveSubcats] = useState([]);
  const [saveCats, setSaveCats] = useState([]);
  const [activeCatId, setActiveCatId] = useState("");

  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [fetchData, setFetchData] = useState([]);

  const [firstForm, setFirstForm] = useState(true);
  const [planForm, setPlanForm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);

  const handleClickOpen = () => {
    setOpenAddCategoryModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddCategoryModal(false);
    }
  };

  ///////////////////////////////////////////////////
  useEffect(() => {
    //fetch all categories and subcategories from backend
    const fetchItems = async () => {
      try {
        const categoriesResponse = await fetch(`${BASE_URL}/getcategory`);

        if (!categoriesResponse.ok) {
          throw new Error("Error fetching items");
        }

        const data1 = await categoriesResponse.json();

        setCategories(data1.category);

        //fetch all subcategories from backend
        const subcategoriesResponse = await fetch(`${BASE_URL}/subcategories`);

        if (!subcategoriesResponse.ok) {
          throw new Error("Error fetching items");
        }

        const data2 = await subcategoriesResponse.json();
        setSubcategories(data2);
        console.log(data2);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };
    fetchItems();
  }, []);

  const handleCategoryChange = (event) => {
    // setSaveSubcats([])
    setSelectedSubcategories([]);
    const selectedItemId = event.target.value;

    setSelectedItemId(selectedItemId);
    const selectedItem = categories.find((item) => item._id === selectedItemId);

    setSubcategories(selectedItem.subcategories);
    console.log(subcategories);
    setSelectedCategory(event.target.value);
  };

  const handleSubcategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSubcategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  console.log(saveCats);
  const saveCatId = (catId) => {
    setActiveCatId(catId);
  };

  const saveSubcatsHandler = (e, subcatId) => {
    console.log("hello");
    console.log(e.target.checked);
    console.log(subcatId);

    if (e.target.checked === true) {
      setSaveSubcats((prevValue) => [...prevValue, subcatId]);
    } else if (e.target.checked === false) {
      setSaveSubcats((prevValue) => {
        return prevValue.filter((id) => {
          return id !== subcatId;
        });
      });
    }
  };

  const handleCategoriesSelection = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddCategoryModal(false);
    }

    setSaveCats((prevValue) => [...prevValue, activeCatId]);
    setSelectedItem("");
    // setSubcategoriesSelected([]);

    const finalCategory = categories.find((cat) => {
      return cat._id === selectedCategory;
    });

    setCategoryGroup((prevValue) => [
      ...prevValue,
      {
        categoryName: finalCategory.categoryName,
        subCatNames: [selectedSubcategories],
      },
    ]);

    setSelectedCategory("");
    setSelectedSubcategories([]);
  };

  ///////////////////////////////////////////////////////////

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = () => {
    fetch(`${BASE_URL}/getcategory`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error fetching items. Status: " + response.status);
        }
      })
      .then((data) => {
        setFetchData(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
      });
  };

  useEffect(() => {
    //get vendor details
    fetch(`${BASE_URL}/getdetails`, {
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(
            "Error fetching vendor details. Status: " + response.status
          );
        }
      })
      .then((data) => {
        setFormData(data);
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // const handleItemChange = (e) => {
  //   const selectedItemId = e.target.value;
  //   setSelectedItem(selectedItemId);
  //   setCategorySelected(e.target.value);
  //   console.log(e.target.value);

  //   const selectedItem = fetchData.find(
  //     (item) => item.categoryName === selectedItemId
  //   );
  //   setSubcategory(selectedItem.subcategories);
  //   console.log(subcategory);
  // };

  // const selectSubcatHandler = (e) => {
  //   setSubcategoryName(e.target.value);
  //   //console.log(subcategoryName)
  //   console.log(e.target.value);
  // };

  const [formData, setFormData] = useState({
    categoriesSelected: "",
    subcategoriesName: "",
    pincode: "",
    whatsappNumber: "",
    phoneNumber: "",
    city: "",
    email: "",
    companyName: "",
    partnerName: "",
    address: "",
    servicesOffered: "",
    additionalInfo: "",
    overview: "",
    websiteURL: "",
    fbLink: "",
    instaLink: "",
    startingPrice: "",
    maximumPrice: "",
    gstNumber: "",
    projectsNum: "",
    businessYears: "",
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    video1: null,
    video2: null,
  });

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    // formDataToSend.append("categoriesSelected", formData.categoriesSelected);
    // formDataToSend.append("subcategoriesSelected", formData.subcategoriesSelected);
    formDataToSend.append("pincode", formData.pincode);
    formDataToSend.append("phoneNumber", formData.phoneNumber);
    formDataToSend.append("whatsappNumber", formData.whatsappNumber);
    formDataToSend.append("address", formData.address);
    formDataToSend.append("city", formData.city);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("additionalInfo", formData.additionalInfo);
    formDataToSend.append("partnerName", formData.partnerName);
    formDataToSend.append("companyName", formData.companyName);
    formDataToSend.append("servicesOffered", formData.servicesOffered);
    formDataToSend.append("websiteURL", formData.websiteURL);
    formDataToSend.append("fbLink", formData.fbLink);
    formDataToSend.append("instaLink", formData.instaLink);
    formDataToSend.append("gstNumber", formData.gstNumber);
    formDataToSend.append("projectsNum", formData.projectsNum);
    formDataToSend.append("businessYears", formData.businessYears);
    formDataToSend.append("startingPrice", formData.startingPrice);
    formDataToSend.append("maximumPrice", formData.maximumPrice);

    formDataToSend.append("image1", formData.image1);
    formDataToSend.append("image2", formData.image2);
    formDataToSend.append("image3", formData.image3);
    formDataToSend.append("image4", formData.image4);
    formDataToSend.append("video1", formData.image5);
    formDataToSend.append("video2", formData.image5);
    console.log(formDataToSend);
    console.log(formData);

    try {
      await fetch(`${BASE_URL}/updatevendordetails`, {
        method: "POST",
        body: formDataToSend,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
        credentials: "include",
      });
      console.log("Vendor details updated successfully!");
    } catch (error) {
      console.log("Error updating vendor details:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(formData);
  };

  const handleFileChange = (e) => {
    const file1 = e.target.files[0];
    const file2 = e.target.files[0];
    const file3 = e.target.files[0];
    const file4 = e.target.files[0];
    const file5 = e.target.files[0];
    const file6 = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      image1: file1,
      image2: file2,
      image3: file3,
      image4: file4,
      video1: file5,
      video2: file6,
    }));
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar />
      <div className={classes.vendorRegistration}>
        <h2>Edit Details</h2>
        <hr />
        <form onSubmit={handleUpdate}>
          <div className={classes.firstForm}>
            <div className={classes.inputBox}>
              <label htmlFor="nameOfThePartner">
                Name of Proprietor/Partner*
              </label>
              <input
                type="text"
                id="nameOfThePartner"
                value={formData.partnerName}
                name="partnerName"
                required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="nameOfTheCompany">Name of the Company*</label>
              <input
                type="text"
                id="nameOfTheCompany"
                value={formData.companyName}
                name="companyName"
                required
                onChange={handleInputChange}
              />
            </div>
            {/* <div className={classes.inputBox}>
              <label htmlFor="currentPlan">Current Plan</label>
              <input type="text" id="currentPlan" />
            </div> */}
            <div className={classes.inputBox}>
              <label htmlFor="fullAddress">Address*</label>
              <input
                type="text"
                id="fullAddress"
                value={formData.address}
                name="address"
                required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="city">City*</label>
              <input
                type="text"
                id="city"
                value={formData.city}
                name="city"
                required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="pinCode">Pin Code*</label>
              <input
                type="number"
                id="pinCode"
                value={formData.pincode}
                name="pincode"
                required
                onChange={handleInputChange}
              />
            </div>
            {/* <div className={classes.inputBox}>
              <label htmlFor="serviceOffered">Services Offered</label>
              <input
                type="text"
                id="serviceOffered"
                value={formData.servicesOffered}
                name="servicesOffered"
                required
                onChange={handleInputChange}
              />
            </div> */}
            {/* <div className={classes.inputBox}>
              <label htmlFor="category">Category</label>
              <select
                name="category"
                id="category"
                onChange={handleItemChange}
                value={selectedItem}
              >
                <option value=" ">Select an Item</option> {" "}
                {fetchData.map((item) => (
                  <option key={item._id} value={item.categoryName}>
                    {item.categoryName}
                  </option>
                ))}
                <option value="others">others</option>
              </select>
            </div>

            <div className={classes.inputBox}>
              <label htmlFor="subCategory">Sub-Category</label>
              <select
                name="subCategory"
                id="subCategory"
                onChange={selectSubcatHandler}
              >
                <option value=" ">Select a subcategory</option>
                {subcategory.map((subcategory, index) => (
                  <option key={index} value={subcategory.subcategoryName}>
                    {subcategory.subcategoryName}
                  </option>
                ))}
                <option value="others">others</option>
              </select>
            </div> */}

            {/* <div className="allCatWrapper">
                Service(s) offered*
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="allCategoriesDiv">
                    {categoriesSelected.length < 0 ? (
                      <p>Choose Categories...</p>
                    ) : (
                      <ol>
                        {categoryGroup.map((group) => (
                          <li>
                            <p>
                              {group.categoryName}:{" "}
                              {group.subCatNames.join(", ")}
                            </p>
                          </li>
                        ))}
                      </ol>
                    )} 
                  </div>
                  <button type="button" onClick={handleClickOpen}>
                    Add Categories +
                  </button>
                </div>
              </div> */}

            {/* <Dialog
                disableEscapeKeyDown
                open={openAddCategoryModal}
                onClose={handleClose}
              >
                <DialogTitle>Select categories</DialogTitle>
                <DialogContent>
                  <Box
                    component="form"
                    sx={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <InputLabel id="category-select-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="category-select-label"
                        id="category-select"
                       name="categoriesSelected"
                       value={selectedCategory}
                       onChange={handleCategoryChange}
                        input={<OutlinedInput label="Category" />}
                      >
                       
                       {categories.map((category) => (
                          <MenuItem key={category._id} value={category._id} onClick = {()=> saveCatId(category._id)}
                          >{category.categoryName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}

            {/* <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="subcat-select-label">
                        Sub-Category
                      </InputLabel>
                      <Select
                        labelId="subcat-select-label"
                        id="subcat-select-checkbox"
                        multiple
                        value={selectedSubcategories}
                        onChange={handleSubcategoryChange}
                        input={<OutlinedInput label="Sub-Category" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                       {subcategories
                         .map((subcategory) => (
                          <MenuItem key={subcategory._id} value={subcategory.subcategoryName} className="menuItemVendor">
                        
                               <input type="checkbox" id={subcategory._id}
                              checked={selectedSubcategories.indexOf(subcategory.subcategoryName) > -1}
                              onChange={(e)=> saveSubcatsHandler(e, subcategory._id)}     
                            />
                            <label htmlFor={subcategory._id}>{subcategory.subcategoryName}</label>
                             </MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleCategoriesSelection}>Ok</Button>
                </DialogActions>
              </Dialog> */}

            <div className={classes.inputBox}>
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                name="email"
                disabled
                required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="Mobile Number">Mobile Number*</label>
              <input
                type="number"
                id="Mobile Number"
                value={formData.phoneNumber}
                name="phoneNumber"
                required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="whatsapp Number">WhatsApp Number*</label>
              <input
                type="number"
                id="whatsapp Number"
                value={formData.whatsappNumber}
                name="whatsappNumber"
                required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="website url">Website URL</label>
              <input
                type="text"
                id="website url"
                value={formData.websiteURL}
                name="websiteURL"
                // required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="Facebook page link">Facebook page link</label>
              <input
                type="text"
                id="Facebook page link"
                value={formData.fbLink}
                name="fbLink"
                // required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="Facebook page link">Instagram page link</label>
              <input
                type="text"
                id="Facebook page link"
                value={formData.instaLink}
                name="instaLink"
                // required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="GST Number">GST Number</label>
              <input
                type="text"
                id="GST Number"
                value={formData.gstNumber}
                name="gstNumber"
                // required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="projectsNum">Number of Projects till date*</label>
              <input
                type="number"
                id="projectsNum"
                value={formData.projectsNum}
                name="projectsNum"
                required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="businessYears">Years in business*</label>
              <input
                type="number"
                id="businessYears"
                value={formData.businessYears}
                name="businessYears"
                required
                onChange={handleInputChange}
              />
            </div>

            <div className={classes.inputBox}>
              {/* <label htmlFor="overview">Overview</label>
              <textarea id="overview" rows={4} cols={40}></textarea> */}
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="startingPrice">Starting price*</label>
              <input
                type="number"
                id="startingPrice"
                value={formData.startingPrice}
                name="startingPrice"
                required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="maximumPrice">Maximum price*</label>
              <input
                type="number"
                id="maximumPrice"
                value={formData.maximumPrice}
                name="maximumPrice"
                required
                onChange={handleInputChange}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="overview">Overview*</label>
              <textarea
                id="overview"
                rows={4}
                cols={40}
                value={formData.overview}
                name="overview"
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="additionalInfo">Additional Information*</label>
              <textarea
                id="additionalInfo"
                rows={4}
                cols={40}
                value={formData.additionalInfo}
                name="additionalInfo"
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>

          {/* FOR PREMIUM VENDORS */}
          {formData.planSelected === "premium" && (
            <>
              <h2 className={classes.inputBox}>Upload content</h2>
              <hr />
              <div className={classes.secondForm}>
                <div className={classes.inputBox}>
                  <label htmlFor="landscapeImage">Upload small image1*</label>
                  <input
                    type="file"
                    name="image3"
                    onChange={handleFileChange}
                    id="landscapeImage"
                    className={classes.fileInput}
                  />
                </div>
                <div className={classes.inputBox}>
                  <label htmlFor="firstImage">Upload small image2*</label>
                  <input
                    type="file"
                    name="image1"
                    onChange={handleFileChange}
                    className={classes.fileInput}
                    id="firstImage"
                  />
                </div>
                <div className={classes.inputBox}>
                  <label htmlFor="secondImage">Upload small image 3*</label>
                  <input
                    type="file"
                    name="image2"
                    onChange={handleFileChange}
                    className={classes.fileInput}
                    id="secondImage"
                  />
                </div>
                <div className={classes.inputBox}>
                  <label htmlFor="thirdImage">Upload small image 4*</label>
                  <input
                    type="file"
                    className={classes.fileInput}
                    name="image4"
                    onChange={handleFileChange}
                    id="thirdImage"
                  />
                </div>
                <div className={`${classes.inputBox} ${classes.videoInput}`}>
                  <label htmlFor="video">Upload video1*</label>
                  <input
                    type="file"
                    className={classes.fileInput}
                    id="video"
                    name="video1"
                    onChange={handleFileChange}
                  />
                </div>
                <div className={`${classes.inputBox} ${classes.videoInput}`}>
                  <label htmlFor="video">Upload video2*</label>
                  <input
                    type="file"
                    className={classes.fileInput}
                    id="video"
                    name="video2"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </>
          )}
          <button type="submit" className={classes.vendorSubmit}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default EditProfile;
