import React from "react";
import { BASE_URL } from "../../apiConfig";
import classes from "./TableVendor.module.css";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";

function Table() {
  const [fetchData, setFetchData] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [subcategory, setSubcategory] = useState([]);
  const [categorySelected, setCategorySelected] = useState("");
  const [subcategoryName, setSubcategoryName] = useState("");
  const [isView, setIsView] = useState(false);

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = () => {
    fetch(`${BASE_URL}/getcategory`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error fetching items");
        }
        return response.json();
      })
      .then((data) => {
        setFetchData(data.category);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
      });
  };

  const [userData, setUserData] = useState([]);
  const viewCustomerHandler = (id) => {
    setIsView(true);

    fetch(`${BASE_URL}/getusers/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error fetching data");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setUserData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  ///////////////////////////
  const handleItemChange = (e) => {
    const selectedItemId = e.target.value;
    setSelectedItem(selectedItemId);
    setCategorySelected(e.target.value);
    console.log(e.target.value);

    const selectedItem = fetchData.find(
      (item) => item.categoryName === selectedItemId
    );
    setSubcategory(selectedItem.subcategories);
    console.log(subcategory);
  };

  const selectSubcatHandler = (e) => {
    setSubcategoryName(e.target.value);
    //console.log(subcategoryName)
    console.log(e.target.value);
  };
  //////////////////////////

  const [form, setForm] = useState(false);
  const [form2, setForm2] = useState(false);
  const [items, setItems] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [selectedRowId, setSelectedRowId] = useState(null);

  const [updatedData, setUpdatedData] = useState(null);

  const [formData, setFormData] = useState({
    categorySelected: "",
    subcategoryName: "",
    pincode: "",
    whatsappNumber: "",
    phoneNumber: "",
    businessYears: "",
    projectsNum: "",
    city: "",
    email: "",
    companyName: "",
    partnerName: "",
    address: "",
    servicesOffered: "",
    additionalInfo: "",
    overview: "",
    websiteURL: "",
    fbLink: "",
    instaLink: "",
    startingPrice: "",
    maximumPrice: "",
    gstNumber: "",
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    video1: null,
    video2: null,
  });

  useEffect(() => {
    if (selectedRowId) {
      fetch(`${BASE_URL}/vendor/${selectedRowId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error fetching data");
          }
          return response.json();
        })
        .then((data) => {
          setFormData(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedRowId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    console.log("hello");
    const formDataToSend = new FormData();
    formDataToSend.append("categorySelected", formData.categorySelected);
    formDataToSend.append("subcategoryName", formData.subcategoryName);
    formDataToSend.append("pincode", formData.pincode);
    formDataToSend.append("phoneNumber", formData.phoneNumber);
    formDataToSend.append("whatsappNumber", formData.whatsappNumber);
    formDataToSend.append("projectsNum", formData.projectsNum);
    formDataToSend.append("businessYears", formData.businessYears);
    formDataToSend.append("address", formData.address);
    formDataToSend.append("city", formData.city);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("additionalInfo", formData.additionalInfo);
    formDataToSend.append("overview", formData.overview);
    formDataToSend.append("partnerName", formData.partnerName);
    formDataToSend.append("companyName", formData.companyName);
    formDataToSend.append("servicesOffered", formData.servicesOffered);
    formDataToSend.append("websiteURL", formData.websiteURL);
    formDataToSend.append("fbLink", formData.fbLink);
    formDataToSend.append("instaLink", formData.instaLink);
    formDataToSend.append("gstNumber", formData.gstNumber);
    formDataToSend.append("startingPrice", formData.startingPrice);
    formDataToSend.append("maximumPrice", formData.maximumPrice);

    formDataToSend.append("image1", formData.image1);
    formDataToSend.append("image2", formData.image2);
    formDataToSend.append("image3", formData.image3);
    formDataToSend.append("image4", formData.image4);
    formDataToSend.append("video1", formData.video1);
    formDataToSend.append("video2", formData.video2);

    try {
      const response = await fetch(
        `${BASE_URL}/vendor/update/${selectedRowId}`,
        {
          method: "POST",
          body: formDataToSend,
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
        }
      );

      if (!response.ok) {
        throw new Error("Error updating data");
      }

      const data = await response.json();
      setFormData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(formData);
  };

  const handleFileChange = (e) => {
    const file1 = e.target.files[0];
    const file2 = e.target.files[0];
    const file3 = e.target.files[0];
    const file4 = e.target.files[0];
    const file5 = e.target.files[0];
    const file6 = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      image1: file1,
      image2: file2,
      image3: file3,
      image4: file4,
      video1: file5,
      video2: file6,
    }));
  };

  const [data, setData] = useState({
    transactionId: "",
    merchantTransactionId: "",
    paymentDetails: {},
    companyName: "",
    partnerName: "",
    startingPrice: "",
    maximumPrice: "",
    businessYears: "",
    projectsNum: "",
    categoriesSelected: [],
    address: "",
    city: "",
    planSelected: "",
    pincode: "",
    email: "",
    whatsappNumber: "",
    phoneNumber: "",
    websiteURL: "",
    fbLink: "",
    instaLink: "",
    gstNumber: "",
    additionalInfo: "",
    overview: "",
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    video1: "",
    video2: "",
  });

  const viewCategoryHandler = (id) => {
    setOpenForm(true);
    setActiveId(id);
    console.log("hello");
  };

  // const deleteCategoryHandler = (id) =>{
  //     // setActiveId(id);
  //     deleteCategory(id)
  // }

  const closeViewFormHandler = () => {
    setOpenForm(false);
  };

  const viewUpdateCategoryHandler = (id) => {
    setOpenUpdateForm(true);
    setSelectedRowId(id);
  };

  const closeCategoryHandler = () => {
    setOpenUpdateForm(false);
  };

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetch(`${BASE_URL}/vendor/upload`);
        if (!response.ok) {
          throw new Error("Error fetching categories");
        }
        const data = await response.json();
        setItems(data);
      } catch (error) {
        console.log(error);
      }
    };
    getCategories();
  }, []);

  const getData = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/vendor/${id}`);
      if (!response.ok) {
        throw new Error("Error fetching data");
      }
      const data = await response.json();
      setData(data);
      console.log(data.paymentDetails);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(activeId);
  }, [activeId]);

  const deleteCategory = async (id) => {
    try {
      await fetch(`${BASE_URL}/vendor/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      setItems((prevData) => prevData.filter((item) => item._id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.table}>
      <table className={classes["content-table"]}>
        <tbody>
          <tr>
            <th>Business Name</th>
            <th>Business Price Range</th>
            <th>Selected Plan</th>
            <th>Gross Hits</th>
            <th>Customers</th>
            <th>Action</th>
          </tr>
          {items.map((val, index) => {
            return (
              <tr key={index}>
                <td>{val.companyName}</td>
                <td>{`₹${val.startingPrice}-₹${val.maximumPrice}`}</td>
                <td>{val.planSelected}</td>
                <td>{val.grossHits}</td>
                <td>
                  <VisibilityIcon
                    fontSize="small"
                    onClick={() => {
                      viewCustomerHandler(val._id);
                    }}
                  />
                </td>
                <td>
                  <div className={classes.action}>
                    <EditIcon
                      fontSize="small"
                      onClick={() => {
                        viewUpdateCategoryHandler(val._id);
                      }}
                    />
                    <VisibilityIcon
                      fontSize="small"
                      onClick={() => {
                        viewCategoryHandler(val._id);
                      }}
                    />
                    <DeleteIcon
                      fontSize="small"
                      onClick={() => {
                        deleteCategory(val._id);
                      }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal open={openUpdateForm} onClose={() => setOpenUpdateForm(false)}>
        <Fade in={openUpdateForm}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: "80%",
              // maxHeight: "500px",
              maxHeight: "30rem",
              width: "80%", //default width for all screens
              "@media (max-width: 768px)": {
                width: "85%" /* Width for screens up to 768px */,
                maxHeight: "85%",
              },
              "@media (max-width: 480px)": {
                width: "95%" /* Width for screens up to 480px */,
                maxHeight: "95%",
              },
              padding: " 20px",
              overflow: "auto",
              bgcolor: "#FFF",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div className={classes.editCategoryForm} id={classes.formActive}>
              <div className={classes["editCategoryForm-Header"]}>
                <h3>Edit Vendor</h3>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={closeCategoryHandler}
                />
              </div>
              <hr />
              <div className={classes["editCategoryForm-content"]}>
                <h3>Vendor's Information</h3>

                <form onClick={handleUpdate}>
                  {/* <div className={classes.formInput}>
                    <label>Select Category</label>
                    <select
                      value={selectedItem}
                      onChange={handleItemChange}
                      className={classes.categoryInput}
                    >
                        <option value=" ">Select an Item</option> {" "}
                      {fetchData.map((item) => (
                        <option key={item._id} value={item.categoryName}>
                          {item.categoryName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className={classes.formInput}>
                    <label>Select a Subcategory</label>
                    <select
                      name="subcategoryName"
                      className={classes.categoryInput}
                      onChange={selectSubcatHandler}
                    >
                        <option value=" ">Select a subcategory</option>
                      {subcategory.map((subcategory, index) => (
                        <option key={index} value={subcategory.subcategoryName}>
                          {subcategory.subcategoryName}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  {/* <div className={classes.formInput}>
                <label>Enter Business Name </label>
                <input
                  className={classes.categoryInput}
                  type="text"
                  value={formData.companyName}
                  name="companyName"              
                  onChange={handleInputChange}
                />
              </div> */}

                  <div className={classes.formInput}>
                    <label>Enter Partner Name </label>
                    <input
                      className={classes.categoryInput}
                      type="text"
                      value={formData.partnerName}
                      name="partnerName"
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Enter Company Name </label>
                    <input
                      className={classes.categoryInput}
                      type="text"
                      value={formData.companyName}
                      name="companyName"
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Enter Full Address</label>
                    <input
                      className={classes.categoryInput}
                      type="text"
                      value={formData.address}
                      name="address"
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Enter City</label>
                    <input
                      className={classes.categoryInput}
                      type="text"
                      value={formData.city}
                      name="city"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Enter Pincode</label>
                    <input
                      className={classes.categoryInput}
                      type="number"
                      value={formData.pincode}
                      name="pincode"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Enter Mobile Number </label>
                    <input
                      className={classes.categoryInput}
                      type="number"
                      value={formData.phoneNumber}
                      name="phoneNumber"
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* <div className={classes.formInput}>
                    <label>Enter Services Offered</label>
                    <input
                      className={classes.vendorInput}
                      type="text"
                      value={formData.servicesOffered}
                      name="servicesOffered"
                      required
                      onChange={handleInputChange}
                    />
                  </div> */}
                  <div className={classes.formInput}>
                    <label>Enter Website URL</label>
                    <input
                      className={classes.vendorInput}
                      type="text"
                      value={formData.websiteURL}
                      name="websiteURL"
                      // required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Enter Instagram Page Link</label>
                    <input
                      className={classes.vendorInput}
                      type="text"
                      value={formData.instaLink}
                      name="instaLink"
                      // required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Enter Facebook Page Link</label>
                    <input
                      className={classes.vendorInput}
                      type="text"
                      value={formData.fbLink}
                      name="fbLink"
                      // required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Enter GST Number</label>
                    <input
                      className={classes.vendorInput}
                      type="number"
                      value={formData.gstNumber}
                      name="gstNumber"
                      // required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Enter Starting Price</label>
                    <input
                      className={classes.vendorInput}
                      type="number"
                      value={formData.startingPrice}
                      name="startingPrice"
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Enter Maximum Price</label>
                    <input
                      className={classes.vendorInput}
                      type="number"
                      value={formData.maximumPrice}
                      name="maximumPrice"
                      required
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className={classes.formInput}>
                    <label> Enter whatsapp number </label>
                    <input
                      className={classes.categoryInput}
                      type="number"
                      value={formData.whatsappNumber}
                      name="whatsappNumber"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Number of Projects till date</label>
                    <input
                      className={classes.categoryInput}
                      type="number"
                      value={formData.projectsNum}
                      name="projectsNum"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Years in business</label>
                    <input
                      className={classes.categoryInput}
                      type="number"
                      value={formData.businessYears}
                      name="businessYears"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInput}>
                    <label>Enter Email </label>
                    <input
                      className={classes.categoryInput}
                      type="text"
                      value={formData.email}
                      name="email"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className={classes.formInputBig}>
                    <label htmlFor="overview">Overview</label>
                    <textarea
                      id={classes.overview}
                      rows={4}
                      cols={40}
                      className={classes.categoryInput}
                      value={formData.overview}
                      name="overview"
                      required
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  <div className={classes.formInputBig}>
                    <label htmlFor="additionalInfo">
                      Additional Information
                    </label>
                    <textarea
                      id={classes.additionalInfo}
                      rows={4}
                      cols={40}
                      className={classes.categoryInput}
                      value={formData.additionalInfo}
                      name="additionalInfo"
                      required
                      onChange={handleInputChange}
                    ></textarea>
                  </div>

                  {formData.planSelected === "premium" && (
                    <>
                      <div className={classes.formInput}>
                        <label>Upload image1</label>
                        <input
                          type="file"
                          name="image1"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className={classes.formInput}>
                        <label>Upload image2</label>
                        <input
                          type="file"
                          name="image2"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className={classes.formInput}>
                        <label>Upload image3</label>
                        <input
                          type="file"
                          name="image3"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className={classes.formInput}>
                        <label>Upload image4</label>
                        <input
                          type="file"
                          name="image4"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className={classes.formInput}>
                        <label>Upload video 1</label>
                        <input
                          type="file"
                          name="video1"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className={classes.formInput}>
                        <label>Upload video 2</label>
                        <input
                          type="file"
                          name="video2"
                          onChange={handleFileChange}
                        />
                      </div>
                    </>
                  )}
                  <div className={classes.formControl}>
                    <button onClick={closeCategoryHandler}> Close</button>
                    <button type="button" onClick={closeCategoryHandler}>
                      Submit
                    </button>
                  </div>
                  {/* </div> */}
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* View vendor */}
      <Modal open={openForm} onClose={() => setForm(false)}>
        <Fade in={openForm}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxHeight: "500px",
              padding: " 20px",
              overflow: "auto",
              bgcolor: "#FFF",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div
              className={classes.viewCategoryForm}
              id={form2 ? classes.formActive : " "}
            >
              <div className={classes["viewCategoryForm-Header"]}>
                <h3>View Vendor</h3>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={closeViewFormHandler}
                />
              </div>
              <hr />
              <div className={classes["viewCategoryForm-content"]}>
                <h3>Vendor's Information</h3>

                <form>
                  <div className={classes.formInput}>
                    <label>Vendor Id</label>
                    <h4>{data._id}</h4>
                  </div>
                  {data.merchantTransactionId && (
                    <div className={classes.formInput}>
                      <label>Merchant Transaction Id</label>
                      <h4>{data.merchantTransactionId}</h4>
                    </div>
                  )}
                  {data.transactionId && (
                    <div className={classes.formInput}>
                      <label>Transaction Id</label>
                      <h4>{data.transactionId}</h4>
                    </div>
                  )}
                  {data.paymentDetails?.data?.state && (
                    <div className={classes.formInput}>
                      <label>Payment Status</label>
                      <h4>{data.paymentDetails.data.state}</h4>
                    </div>
                  )}
                  {data.paymentDetails?.data?.amount && (
                    <div className={classes.formInput}>
                      <label>Amount Paid</label>
                      <h4>
                        ₹
                        {(
                          parseInt(data.paymentDetails.data.amount) / 100
                        ).toFixed(2)}
                      </h4>
                    </div>
                  )}

                  <div className={classes.formInput}>
                    <label>Company Name*</label>
                    <h4>{data.companyName}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Partner's Name*</label>
                    <h4>{data.partnerName}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Business Price Range *</label>
                    <h4>{`₹${data.startingPrice}-₹${data.maximumPrice}`}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Years in business *</label>
                    <h4>{data.businessYears}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Number of projects till date*</label>
                    <h4>{data.projectsNum}</h4>
                  </div>
                  <div
                    className={classes.formInput}
                    // style={{ maxHeight: "150px", overflow: "auto" }}
                  >
                    <label>Categories Selected*</label>
                    {/* <h4>{data.categoriesSelected}</h4> */}
                    <ul>
                      {data.categoriesSelected.map((category) => (
                        <li>{category.categoryName}</li>
                      ))}
                    </ul>
                  </div>
                  {/* <div className={classes.formInput}>
                    <label>Subcategories Selected*</label>
                    <h4>{data.subcategoriesSelected}</h4>
                  </div> */}
                  <div className={classes.formInput}>
                    <label>Address *</label>
                    <h4>{data.address}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>City *</label>
                    <h4>{data.city}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Pincode*</label>
                    <h4>{data.pincode}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Mobile Number*</label>
                    <h4>{data.phoneNumber}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Whatsapp number*</label>
                    <h4>{data.whatsappNumber}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Email *</label>
                    <h4>{data.email}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Website URL</label>
                    <h4>{data.websiteURL}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Facebook Link</label>
                    <h4>{data.fbLink}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>Instagram Link</label>
                    <h4>{data.instaLink}</h4>
                  </div>
                  <div className={classes.formInput}>
                    <label>GST Number</label>
                    <h4>{data.gstNumber}</h4>
                  </div>

                  <div className={classes.formInputBig}>
                    <label style={{ fontWeight: "bold" }}>
                      Additional Information*
                    </label>
                    <h6>{data.additionalInfo}</h6>
                  </div>
                  <div className={classes.formInputBig}>
                    <label style={{ fontWeight: "bold" }}>Overview*</label>
                    <h6>{data.overview}</h6>
                  </div>

                  {data.planSelected === "premium" && (
                    <>
                      <div className={classes.formInput}>
                        <label>Uploaded image 1</label>
                        <Link to={`${BASE_URL}/upload/${data.image1}`}>
                          View
                        </Link>
                      </div>
                      <div className={classes.formInput}>
                        <label>uploaded image 2 </label>
                        <Link to={`${BASE_URL}/upload/${data.image2}`}>
                          View
                        </Link>
                      </div>
                      <div className={classes.formInput}>
                        <label>uploaded image 3 </label>
                        <Link to={`${BASE_URL}/upload/${data.image3}`}>
                          View
                        </Link>
                      </div>
                      <div className={classes.formInput}>
                        <label>uploaded image 4 </label>
                        <Link to={`${BASE_URL}/upload/${data.image4}`}>
                          View
                        </Link>
                      </div>
                      <div className={classes.formInput}>
                        <label>uploaded video 1 </label>
                        <Link to={`${BASE_URL}/upload/${data.video1}`}>
                          View
                        </Link>
                      </div>
                      <div className={classes.formInput}>
                        <label>uploaded video 2</label>
                        <Link to={`${BASE_URL}/upload/${data.video2}`}>
                          View
                        </Link>
                      </div>
                    </>
                  )}
                  <div className={classes.formControl}>
                    <button type="button" onClick={closeViewFormHandler}>
                      {" "}
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal open={isView} onClose={() => setIsView(false)}>
        <Fade in={isView}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              overflow: "auto",
              maxHeight: "30rem",
              width: "60%", //default width for all screens
              "@media (max-width: 768px)": {
                width: "85%" /* Width for screens up to 768px */,
                maxHeight: "55%",
              },
              "@media (max-width: 480px)": {
                width: "95%" /* Width for screens up to 480px */,
                maxHeight: "65%",
              },
              bgcolor: "#FFF",
              borderRadius: "15px",
              boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <table
              className={classes["content-table"]}
              style={{ width: "100%", margin: "0" }}
            >
              <tbody>
                <tr>
                  <th>Customer Name</th>
                  <th>Customer Email</th>
                  <th>Customer Phone</th>
                </tr>
                {/*               
                <tr>
                  <td>Akash Planners</td>
                  <td>akash@gmail.com</td>
                  <td>8989978767</td>
                </tr>
                <tr>
                  <td>Raj Caterers</td>
                  <td>raj@gmail.com</td>
                  <td>8989978767</td>
                </tr>
                <tr>
                  <td>XYZ Decorators</td>
                  <td>xyz@gmail.com</td>
                  <td>8989978767</td>
                </tr>
                <tr>
                  <td>Diyaz Planners</td>
                  <td>diyaz@gmail.com</td>
                  <td>8989978767</td>
                </tr>
                <tr>
                  <td>Kala Mehndi</td>
                  <td>kala@gmail.com</td>
                  <td>8989978767</td>
                </tr> */}

                {userData.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td>{`${val.firstname} ${val.lastname}`}</td>
                      <td>{val.email}</td>
                      <td>{val.phone}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default Table;
